import { UsePersonalizedModule } from "flamingo-e-learning-platform/training/hooks/use-personalized-module";
import { PersonalizedModule } from "flamingo-e-learning-platform/training/types/module.type";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { welcomeMessageUtils } from ".";

const DefaultMessage = () => {
  const { t } = useTranslation("flamingo");
  return <p>{t("welcome_description")}</p>;
};

/**
 * @description This function provides the utils method responsible for calculating and
 * return the right messages depending on the learner status.
 * the order of the utils messages represents priority, the 0 highest priority
 * All utils method must return a component/ ()=> React.ReactElement
 */
const WelcomeCardMessageProvider = (
  personalizedModules: PersonalizedModule[],
  date: string,
  userLanguage: string,
  learningDaysLimit: number
) => {
  const messages = [
    welcomeMessageUtils.GetOverDueMessage(personalizedModules, date),
  ];

  // Only add learning status message if learning path is limited
  if (learningDaysLimit !== 0) {
    messages.push(
      welcomeMessageUtils.GetLearningStatusMessage(
        personalizedModules,
        learningDaysLimit,
        userLanguage
      )
    );
  }

  return messages;
};

/**
 * @description Go through the list of utils methods and call them to get the right message
 * or null, if null is returned by the method, means no message to be shown for
 * that method.
 */
const getWelcomeCardMessageComponent = (
  personalizedModules: PersonalizedModule[],
  userLanguage: string,
  learningDaysLimit: number
) => {
  const result = WelcomeCardMessageProvider(
    personalizedModules,
    new Date().toISOString(),
    userLanguage,
    learningDaysLimit
  ).find((messageComponent) => messageComponent !== null);

  return result;
};

export const UseWelcomeCardMessage = (
  userLanguage: string,
  learningDaysLimit: number
) => {
  const { personalizedModules, isLoading } = UsePersonalizedModule();
  const [MessageComponent, setMessageComponent] =
    useState<() => React.ReactElement>();

  useEffect(() => {
    if (isLoading) return;

    const selectedMessageComponent = getWelcomeCardMessageComponent(
      personalizedModules,
      userLanguage,
      learningDaysLimit
    );

    if (selectedMessageComponent) {
      setMessageComponent(() => selectedMessageComponent);
      return;
    }

    setMessageComponent(() => DefaultMessage);
  }, [personalizedModules, isLoading]);

  return MessageComponent;
};
