import { PersonalizedModule } from "flamingo-e-learning-platform/training/types/module.type";
import { availableOn } from "./available-on-with-limit";
import isOverDue from "./is-module-overdue";

export const updateModulesByLimit = (modules: PersonalizedModule[], limit: number) => {
  if (!limit) return modules;

  const today = new Date();

  return modules.map((lesson) => {
    if (!lesson.finished_by) {
      return {
        ...lesson,
        ahead: false,
        availableOn: today.toISOString(),
      };
    }
    const moduleIsAvailableOn = availableOn(lesson.finished_by, limit);
    const isOverdueModule = isOverDue(lesson.finished_by);
    const unlocked = today >= new Date(moduleIsAvailableOn) || isOverdueModule;

    return {
      ...lesson,
      ahead: !unlocked,
      availableOn: moduleIsAvailableOn.toISOString(),
    };
  });
};
