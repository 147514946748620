import React, { useMemo } from "react";
import SvgIcon from "shared/svgs/helper/svg-icon";
import { UsePersonalizedModule } from "flamingo-e-learning-platform/training/hooks/use-personalized-module";
import { MODULE_STATUS } from "flamingo-e-learning-platform/training/types/module-status.type";
import { useTranslation } from "react-i18next";
import { Close, Play } from "shared/svgs";
import { useHistory } from "react-router-dom";
import { getModuleKey, MODULE_OPENED_FROM } from "elearning/helpers";
import {
  filteredLearningPath,
  limitedContentDays,
} from "flamingo-e-learning-platform/training/components/shared";
import useUser from "shared/hooks/use-user";
import styles from "./next-module-preview.module.css";

interface NextModulePreviewProps {
  closeNextModulePreview: () => void;
}

export const NextPersonalizedModulePreview = ({
  closeNextModulePreview,
}: NextModulePreviewProps) => {
  const { t } = useTranslation("translations");
  const { personalizedModules, isLoading } = UsePersonalizedModule();
  const history = useHistory();
  const { user } = useUser({});
  const customerId = user?.customer?.id || 0;
  const campaignLimitedDays = user?.campaign?.module_unlock_days || 0;
  const learningDaysLimit = limitedContentDays(
    customerId || 0,
    campaignLimitedDays
  );

  const nextModule = useMemo(() => {
    // Get the list of modules that are avaialble in the current learning path
    const modulesInLearningPath = filteredLearningPath(
      personalizedModules,
      learningDaysLimit
    );
    const mandatoryModules = modulesInLearningPath.filter(
      (mod) => mod.mandatory
    );
    return mandatoryModules.find((mod) => mod.status !== MODULE_STATUS.RESTART);
  }, [personalizedModules]);

  const onGoToNextModule = () => {
    if (nextModule) {
      history.push(
        `/elearning/${nextModule.category_key}/${getModuleKey(nextModule)}`,
        {
          openedFrom: MODULE_OPENED_FROM.NEXT_MODULE_POP_UP,
        }
      );
    }
  };

  if (!nextModule || isLoading) return null;

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.topBunner}>
          <h4>{t("Next module")}</h4>
          <button
            aria-label={t("closeNextModulePreview")}
            onClick={closeNextModulePreview}
          >
            <SvgIcon
              Icon={Close}
              color="white"
              size="20px"
              testid="close-icon"
            />
          </button>
        </div>
        <img src={nextModule.thumbnailUrl} alt={nextModule.name} />
        <div className={styles.playIcon}>
          <button aria-label={t("goToNextModule")} onClick={onGoToNextModule}>
            <SvgIcon Icon={Play} size="64px" testid="play-icon" />
          </button>
        </div>
        <div className={styles.bottomBunner}>
          <h4>{nextModule.name}</h4>
        </div>
      </div>
    </div>
  );
};
