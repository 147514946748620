import { PersonalizedModule } from "flamingo-e-learning-platform/training/types/module.type";
import React from "react";
import { useTranslation } from "react-i18next";
import { PathModuleCard } from "../module-card/path-module-card/path-module-card";
import { ModuleCardController } from "../module-card/module-card-controller";
import { WelcomeCard } from "../welcome-card/welcome-card";

import { FLAGS, updateModulesByLimit } from "../shared";

import style from "./mobile-view.module.css";

type ModuleViewType = {
  onModuleCard: (module: PersonalizedModule) => void;
  mandatoryModules: PersonalizedModule[];
  learningDaysLimit: number;
};

export const MobileView = (props: ModuleViewType) => {
  const { onModuleCard, mandatoryModules, learningDaysLimit } = props;
  const { t } = useTranslation("flamingo");

  const limitedModules = updateModulesByLimit(
    mandatoryModules,
    learningDaysLimit
  );

  const sliceModules = (modules: PersonalizedModule[]) =>
    modules.slice(0, FLAGS.limitedDisplayModules);

  const displayedModules = sliceModules(limitedModules);

  return (
    <div className={style.container}>
      <WelcomeCard />
      <h2 className={style.headerText}>{t("learning-path")}</h2>
      <div className={style.modules}>
        {displayedModules.map((mod, i) => (
          <ModuleCardController
            key={mod.name}
            buttonSize="xs"
            highLight={i === 0}
            {...mod}
            onAction={onModuleCard}
            ModuleVariant={PathModuleCard}
          />
        ))}
      </div>
    </div>
  );
};
