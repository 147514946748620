import React, { useMemo, useId } from "react";
import { ClockIcon } from "@sosafe-platform-engineering/fe-lib-ui-react";
import {
  Card,
  Badge,
  Progress,
  Text,
  Button,
  Group,
  Pill,
} from "@sosafe-platform-engineering/fe-lib-ui-mantine-react";
import { IconLock } from "@sosafe-platform-engineering/fe-lib-ui-mantine-react/icons";
import { getDayAndMonthYearString } from "shared/utilities/get-day-month-year-string";
import { MODULE_STATUS } from "flamingo-e-learning-platform/training/types/module-status.type";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { getModuleKey } from "elearning/helpers";
import { ModuleCardType } from "../module-card.types";

import style from "./path-module-card.module.css";
import isOverDue from "../../shared/is-module-overdue";

export const PathModuleCard = ({
  ActionButton,
  StatusTag,
  module,
  userLanguage,
  highLight,
}: ModuleCardType): JSX.Element => {
  const { t } = useTranslation("flamingo");
  const history = useHistory();
  const {
    thumbnailUrl,
    name,
    mandatory,
    progress,
    status,
    finished_by,
    duration,
    awarenessAssessmentIds,
    ahead,
    availableOn,
  } = module;
  const moduleFinishBy = getDayAndMonthYearString(finished_by, userLanguage);
  const isModuleOverdue = isOverDue(finished_by);
  const availableOnDate =
    ahead && availableOn
      ? getDayAndMonthYearString(availableOn, userLanguage)
      : "";
  const moduleComplete = status === MODULE_STATUS.RESTART;
  const hideProgressBar = status === MODULE_STATUS.CONTINUE && progress === 100;
  const shouldShowDueDateTag =
    thumbnailUrl && mandatory && finished_by && !moduleComplete;

  const mobileView = useMemo(() => window.innerWidth <= 480, [window]);
  const hasAwarenessAssessment = status == MODULE_STATUS.AWARENESS_ASSESSMENT;

  // Ensure unique ID for progress label due to component duplication in dom for mobile and desktop.
  const progressLabelUniqueIdSuffix = useId();

  const onTakeAwarenessAssessment = () => {
    const randomId =
      awarenessAssessmentIds[
        Math.floor(Math.random() * awarenessAssessmentIds.length)
      ];
    history.push("/awareness-assessment", {
      id: randomId,
      module,
    });
  };

  const onRestartLesson = () => {
    history.push(`/elearning/${module.category}/${getModuleKey(module)}`);
  };

  const progressDisplay = moduleComplete ? 100 : progress;

  return (
    <Card
      withBorder
      radius="lg"
      p={0}
      className={`${style.card} ${
        highLight ? style.highLight : style.nonHighlight
      }`}
    >
      <div
        className={
          ahead && !moduleComplete ? style.moduleLocked : style.doNotDisplay
        }
      >
        <div className={style.lockerBody}>
          <IconLock className={style.lockerIcon} aria-hidden="true" />
          {availableOnDate && (
            <Pill size="sm" color="white">
              {t("available_On", { availableOnDate })}
            </Pill>
          )}
        </div>
      </div>
      {thumbnailUrl && (
        <div className={style.headerImage}>
          <img src={thumbnailUrl} alt={""} />
        </div>
      )}
      <div className={style.body}>
        <div className={style.content}>
          <div className={style.label}>
            <h3>{name}</h3>
          </div>
          <div className={style.details}>
            <div className={style.tags}>
              {(!moduleComplete || hasAwarenessAssessment) && (
                <Badge variant={"warning"} size={mobileView ? "xs" : "md"}>
                  {t("mandatory")}
                </Badge>
              )}

              <StatusTag tagSize={mobileView ? "xs" : "md"} />

              {shouldShowDueDateTag && moduleFinishBy && (
                <Badge
                  variant={isModuleOverdue ? "danger" : "neutral"}
                  size={mobileView ? "xs" : "md"}
                >{`${t("dueOn")} ${moduleFinishBy}`}</Badge>
              )}
            </div>
            {!hasAwarenessAssessment && (
              <div className={style.duration}>
                <ClockIcon
                  aria-label={t("averageDuration")}
                  role="img"
                  className={style.icon}
                />
                <small className={style.durationText}>
                  ~{duration} {t("min")}
                </small>
              </div>
            )}
          </div>
          {!hasAwarenessAssessment && (
            <div
              className={
                hideProgressBar ? style.hideProgressBar : style.progressBarMenu
              }
            >
              {!hideProgressBar && (
                <Group justify="start" style={{ width: "100%" }}>
                  <Progress
                    role="progressbar"
                    aria-valuenow={progressDisplay}
                    aria-valuemin={0}
                    aria-valuemax={100}
                    value={Math.floor(progressDisplay)}
                    className={style.progressBar}
                    aria-labelledby={`progress-label-${module.id}-${progressLabelUniqueIdSuffix}`}
                    aria-label={t("lesson_progress_summary", {
                      percentage: progressDisplay,
                    })}
                  />
                  <Text size="xs" c="#000">
                    {progressDisplay}%
                  </Text>
                  <span
                    className="sr-only"
                    id={`progress-label-${module.id}-${progressLabelUniqueIdSuffix}`}
                  >
                    {t("lesson_progress_summary", {
                      percentage: progressDisplay,
                    })}
                  </span>
                </Group>
              )}
              <ActionButton type="learning-path" />
            </div>
          )}

          {hasAwarenessAssessment && (
            <div className={style.awarenessAssessmentContainer}>
              <Button
                size="xs"
                variant={highLight ? "primary" : "secondary"}
                onClick={onTakeAwarenessAssessment}
              >
                {t("awarenessAssessment_pathModuleCard_button_takeAssessment")}
              </Button>
              <Button
                size="xs"
                variant={highLight ? "secondary" : "naked"}
                onClick={onRestartLesson}
              >
                {t("awarenessAssessment_pathModuleCard_button_restartLesson")}
              </Button>
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};
