import React from "react";
import { ClockIcon } from "@sosafe-platform-engineering/fe-lib-ui-react";
import {
  Badge,
  Card,
  Pill,
} from "@sosafe-platform-engineering/fe-lib-ui-mantine-react";
import { useTranslation } from "react-i18next";
import { MODULE_STATUS } from "flamingo-e-learning-platform/training/types/module-status.type";
import { IconLock } from "@sosafe-platform-engineering/fe-lib-ui-mantine-react/icons";
import { getDayAndMonthYearString } from "shared/utilities/get-day-month-year-string";
import { ModuleCardType } from "../module-card.types";

import style from "./library-module-card.module.css";

export const LibraryModuleCard = ({
  ActionButton,
  StatusTag,
  module,
  userLanguage,
}: ModuleCardType): JSX.Element => {
  const { name, duration } = module;
  const { t } = useTranslation("flamingo");

  const moduleCompleted = module.status === MODULE_STATUS.RESTART;
  const { mandatory, ahead, availableOn } = module;
  const availableOnDate =
    ahead && availableOn
      ? getDayAndMonthYearString(availableOn, userLanguage)
      : "";

  const shouldShowMandatoryTag = !moduleCompleted && mandatory;
  const shouldShowOptionalTag = !moduleCompleted && !mandatory;

  return (
    <Card withBorder radius={'lg'} className={style.container}>
      <div
        className={
          ahead && !moduleCompleted ? style.moduleLocked : style.doNotDisplay
        }
      >
        <div className={style.lockerBody}>
          <IconLock className={style.lockerIcon} aria-hidden="true" />
          {availableOnDate && (
            <Pill size="sm" color="white">
              {t("available_On", { availableOnDate })}
            </Pill>
          )}
        </div>
      </div>
      <div className={style.body}>
        <h5 className={style.name}>{name}</h5>
        <div className={style.details}>
          <div className={style.detailsLeft}>
            <div className={style.duration}>
              <ClockIcon
                aria-label={t("averageDuration")}
                role="img"
                className={style.icon}
              />
              <small className={style.durationText}>
                {duration} {t("min")}
              </small>
            </div>
            <div className={style.tag}>
              {shouldShowMandatoryTag && (
                <Badge variant="warning" size="xs">
                  {t("mandatory")}
                </Badge>
              )}

              {shouldShowOptionalTag && (
                <Badge variant="optional" size="xs">
                  {t("optional")}
                </Badge>
              )}

              <StatusTag tagSize="xs" />
            </div>
          </div>
          <ActionButton type="library" />
        </div>
      </div>
    </Card>
  );
};
