import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Provider } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import clsx from "clsx";

import RewardModal from "shared/components/reward-modal";

import { store } from "state/store/store";

import { MantineProvider } from "@sosafe-platform-engineering/fe-lib-ui-mantine-react";
import isPLuser from "shared/helpers/personalizedLearning/is-pl-user";
import useUser from "shared/hooks/use-user";
import { LevelProgressProvider } from "../level-progress";
import {
  queryClient,
  QueryContextProvider,
  queryKey,
  SoSafeAxiosProvider,
} from "../sosafe-connect";
import { useNotificationSystemContext } from "./notification-system-context";
import "./notification-system.css";

const MySwal = withReactContent(Swal);
type ModalType = "feedbackModal" | "xpModal" | "levelModal" | "badgeModal";

const NotificationSystem = () => {
  const {
    notifications,
    isVisible,
    setNotificationVisible,
    clearNotificationQueue,
    notificationCallback,
  } = useNotificationSystemContext();

  const { i18n } = useTranslation("translations");
  const { user } = useUser({ enabled: false });
  const showPersonalizedLearning = isPLuser(user);

  const modalTitles: Record<ModalType, string | undefined> = {
    feedbackModal: undefined,
    xpModal: i18n.t("Experience points received"),
    levelModal: i18n.t("New level reached"),
    badgeModal: i18n.t("New badge received"),
  };

  /**
   * Replace H2 with H1 in the SweetAlert2 modal title
   */
  const replaceH2WithH1 = () => {
    const titleElement = document.querySelector(".swal2-title");
    if (titleElement && titleElement.tagName === "H2") {
      const h1 = document.createElement("h1");

      // Copy all attributes from H2 to H1
      Array.from(titleElement.attributes).forEach((attr) => {
        h1.setAttribute(attr.name, attr.value);
      });

      h1.innerHTML = titleElement.innerHTML;
      titleElement.parentNode?.replaceChild(h1, titleElement);
    }
  };

  useEffect(() => {
    let timeout;

    const showNotifications = async () => {
      let index = 0;

      // Notification Feedback is always the first modal to be displayed
      const orderedNotificationsToDisplay =
        notifications?.current?.sort(
          (current, next) => current?.displayOrder - next?.displayOrder
        ) || [];
      for (const notification of orderedNotificationsToDisplay) {
        const title = showPersonalizedLearning
          ? modalTitles[notification.type] || undefined
          : undefined;

        await MySwal.fire({
          titleText: title,
          html: (
            <QueryContextProvider>
              <MantineProvider>
                <SoSafeAxiosProvider>
                  <Provider store={store}>
                    <LevelProgressProvider>
                      <RewardModal
                        notificationToShow={notification}
                        totalNotification={notifications?.current?.length}
                      />
                    </LevelProgressProvider>
                  </Provider>
                </SoSafeAxiosProvider>
              </MantineProvider>
            </QueryContextProvider>
          ),

          buttonsStyling: false,
          showConfirmButton: false,
          customClass: {
            popup: clsx({ arab: i18n.language === "ar " }, "w-auto"),
            closeButton: "Notification-system reward-modal-close",
            title: "Notification-system reward-modal-title",
          },
          didOpen: replaceH2WithH1,
          showCloseButton: true,
          closeButtonAriaLabel: i18n.t("Close"),
          focusConfirm: false,
          willClose: () => {
            // whenever we get badges or level the activities are update. invalidating the query makes sure we fetch the latest information when needed.
            queryClient.invalidateQueries([queryKey.ACTIVITIES]);
            queryClient.invalidateQueries([queryKey.BADGES]);
          },
        }).then(() => {
          if (index >= (notifications?.current?.length || 0) - 1) {
            notificationCallback?.current?.();
            clearNotificationQueue();
          }

          setNotificationVisible(false);
        });

        index++;
      }
    };

    if (isVisible) {
      timeout = setTimeout(() => {
        showNotifications();
      }, 300);
    }

    return () => clearInterval(timeout);
  }, [isVisible]);

  return <React.Fragment />;
};

export default NotificationSystem;
