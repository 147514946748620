import React from "react";
import { Text } from "@sosafe-platform-engineering/fe-lib-ui-mantine-react";
import { PersonalizedModule } from "flamingo-e-learning-platform/training/types/module.type";
import { useTranslation } from "react-i18next";
import { MODULE_FILTER_OPTIONS } from "flamingo-e-learning-platform/training/types/module-filter-options";
import { getDayAndMonthYearString } from "shared/utilities/get-day-month-year-string";
import { MODULE_STATUS } from "flamingo-e-learning-platform/training/types/module-status.type";
import { getOverDueModules } from "./due-learning-messages";
import {
  isContentCompleted,
  isAnyModuleCompleted,
  filteredLearningPath,
} from "../../shared";
import filterModules from "../../shared/filter-modules";
import { availableOn } from "../../shared/available-on-with-limit";

type MessageKey =
  | "learning_status_all_completed_message"
  | "learning_status_ahead_message"
  | "learning_status_on_track_message"
  | "welcome_description";

const LearningStatusMessage = ({
  messageKey,
  dateValue,
}: {
  messageKey: MessageKey;
  dateValue?: string;
}) => {
  const { t } = useTranslation("flamingo");
  return (
    <Text size="h4" data-testid={messageKey}>
      {dateValue ? t(messageKey, { dueDate: dateValue }) : t(messageKey)}
    </Text>
  );
};

export const GetLearningStatusMessage = (
  modules: PersonalizedModule[],
  learningDaysLimit: number,
  userLanguage: string
): (() => JSX.Element) | null => {
  const learningPathModules = filteredLearningPath(modules, learningDaysLimit);
  const overdueModules = getOverDueModules(
    learningPathModules,
    new Date().toISOString()
  );
  const allModulesCompletedInLearningPath =
    isContentCompleted(learningPathModules);
  const allMandatoryModules = filterModules(
    modules,
    "all",
    MODULE_FILTER_OPTIONS.MANDATORY
  );
  const isAllMandatoryModulesCompleted =
    isContentCompleted(allMandatoryModules);
  const isAnyMandatoryModulesCompleted =
    isAnyModuleCompleted(learningPathModules);

  const nextTrainingStartDates = modules
    .filter(
      (module) =>
        module.finished_by !== null &&
        typeof module.finished_by === "string" &&
        module.status !== MODULE_STATUS.RESTART &&
        module.mandatory
    )
    .map(
      (module) =>
        module.finished_by &&
        availableOn(module.finished_by, learningDaysLimit).getTime()
    )
    .filter(
      (date): date is number => typeof date === "number" && !Number.isNaN(date)
    );

  const nextTrainingStartDate =
    nextTrainingStartDates.length > 0
      ? new Date(Math.min(...nextTrainingStartDates)).toISOString()
      : null;

  const validDueDates = modules
    .filter(
      (module) =>
        module.finished_by !== null &&
        typeof module.finished_by === "string" &&
        module.status !== MODULE_STATUS.RESTART &&
        module.mandatory
    )
    .map((module) => new Date(module.finished_by as string).getTime());

  const nextEarlyDueDate =
    validDueDates.length > 0
      ? new Date(Math.min(...validDueDates)).toISOString()
      : null;

  const nextEarlyDueDateValue = nextEarlyDueDate
    ? getDayAndMonthYearString(nextEarlyDueDate, userLanguage)
    : null;
  const nextTrainingStartDateValue = nextTrainingStartDate
    ? getDayAndMonthYearString(nextTrainingStartDate, userLanguage)
    : null;
  const dateValue = allModulesCompletedInLearningPath
    ? nextTrainingStartDateValue
    : nextEarlyDueDateValue;

  // Show message if all mandatory modules are completed
  if (overdueModules.length === 0 && isAllMandatoryModulesCompleted) {
    const AllCompletedMessage = () => (
      <LearningStatusMessage messageKey="learning_status_all_completed_message" />
    );
    AllCompletedMessage.displayName = "AllCompletedMessage";
    return AllCompletedMessage;
  }

  // Show default message if none of the modules are completed
  if (
    overdueModules.length === 0 &&
    !isAnyMandatoryModulesCompleted &&
    !allModulesCompletedInLearningPath
  ) {
    const WelcomeDescriptionMessage = () => (
      <LearningStatusMessage messageKey="welcome_description" />
    );
    WelcomeDescriptionMessage.displayName = "WelcomeDescriptionMessage";
    return WelcomeDescriptionMessage;
  }

  // Show message if there are no overdue modules in the learning path
  if (overdueModules.length === 0 && dateValue) {
    const LearningStatusMessageComponent = () => (
      <LearningStatusMessage
        messageKey={
          allModulesCompletedInLearningPath
            ? "learning_status_ahead_message"
            : "learning_status_on_track_message"
        }
        dateValue={dateValue}
      />
    );
    LearningStatusMessageComponent.displayName =
      "LearningStatusMessageComponent";
    return LearningStatusMessageComponent;
  }

  return null;
};
