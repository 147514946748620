import React from "react";
import { useTranslation } from "react-i18next";
import { StarOutlineIcon } from "shared/svgs";
import clsx from "clsx";
import "./feedback.css";

interface RatingSelectionProps {
  setRating(n: number): void;
  rating: number;
}

/**
 * Stateless component showing a star rating selection
 */
export const RatingSelection = ({
  setRating,
  rating,
}: RatingSelectionProps) => {
  const { t } = useTranslation("moduleFeedbackTranslations");

  return (
    <div
      data-testid="rating-section"
      className="d-flex flex-wrap justify-content-between align-items-center my-4"
    >
      <small>{t("relevance_low")}</small>
      <div className="ModuleFeedback-stars">
        {[...Array(5)].map((x, i) => (
          <StarOutlineIcon
            data-testid={`star_${i + 1}`}
            key={`star_${i + 1}`}
            className={clsx(
              { "fill-secondary text-secondary": rating > i },
              "ModuleFeedback-starIcon"
            )}
            height="45px"
            width="45px"
            role="button"
            onClick={() => {
              setRating(i + 1);
            }}
            aria-label={`${i + 1} ${t("x_from_y")} 5`}
          />
        ))}
      </div>
      <small>{t("relevance_high")}</small>
    </div>
  );
};
