import FLAGS from "./check-for-flags";
import { isTestUser } from "./is-it-test-user";

export const limitedContentDays = (customerID: number, campaignLimitedDays: number) => {
  const { learningPathCustomersArray, learningPathLimit, isLearningPathLimited } = FLAGS;

  const isUserLimited = learningPathCustomersArray.includes(customerID);
  const isUserTest = isTestUser(customerID);
  const isCampaignLimited = isLearningPathLimited && campaignLimitedDays > 0;

  let daysLimit: number;

  switch (true) {
    case isUserTest:
      daysLimit = 0;
      break;
    case isUserLimited && !!learningPathLimit:
      daysLimit = learningPathLimit;
      break;
    case isCampaignLimited:
      daysLimit = campaignLimitedDays;
      break;
    default:
      daysLimit = 0;
  }
  return daysLimit;
};
