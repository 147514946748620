import React, { useCallback } from "react";
import { PersonalizedModule } from "flamingo-e-learning-platform/training/types/module.type";
import { MODULE_STATUS } from "flamingo-e-learning-platform/training/types/module-status.type";
import { RefreshCwIcon } from "@sosafe-platform-engineering/fe-lib-ui-react";
import {
  Button,
  Badge,
} from "@sosafe-platform-engineering/fe-lib-ui-mantine-react";
import { useTranslation } from "react-i18next";
import useUser from "shared/hooks/use-user";
import { dispatchSetPlayerData } from "elearning/services";
import { useDispatch } from "react-redux";
import {
  LEARNING_PATH_DISPLAY_BUTTON_BY_STATUS,
  LIBRARY_DISPLAY_BUTTON_BY_STATUS,
} from "./display-button-mapping";

import style from "./module-card-controller.module.css";

interface ModuleCardControllerProps extends PersonalizedModule {
  ModuleVariant: React.ElementType;
  onAction: (module: PersonalizedModule) => void;
  highLight?: boolean;
  buttonSize?: "xs" | "sm" | "md" | "lg";
}

type ActionButtonProps = { type: "library" | "learning-path" };

export const ModuleCardController = ({
  ModuleVariant,
  onAction,
  highLight = false,
  buttonSize = "sm",
  ...module
}: ModuleCardControllerProps) => {
  const { user } = useUser({});

  const userLanguage = user?.language;
  const { thumbnailUrl, mandatory, progress, status, name } = module;
  const dispatch = useDispatch();
  const { t } = useTranslation("flamingo");

  const ActionButton = useCallback(
    ({ type }: ActionButtonProps) => {
      const key = `${status}${progress === 100 ? "complete" : ""}`;
      const displaySet =
        type === "library"
          ? LIBRARY_DISPLAY_BUTTON_BY_STATUS
          : LEARNING_PATH_DISPLAY_BUTTON_BY_STATUS;
      const display = displaySet.get(key);

      return (
        <div className={style.actionBtn}>
          {display?.continue && (
            <Button
              variant={thumbnailUrl && highLight ? "primary" : "secondary"}
              aria-label={`${t("continue_alt")} ${name}`}
              onClick={() => {
                dispatch(dispatchSetPlayerData({ isContinue: true }));
                onAction(module);
              }}
              size={buttonSize}
            >
              {t("continue")}
            </Button>
          )}
          {display?.retry && (
            <Button
              variant="secondary"
              data-testid="refresh-icon"
              aria-label={`${t("restart_alt")} ${name}`}
              onClick={() => onAction(module)}
              className={style.buttonRefresh}
              size={buttonSize}
            >
              <RefreshCwIcon />
            </Button>
          )}
          {display?.start && (
            <Button
              variant={thumbnailUrl && highLight ? "primary" : "secondary"}
              aria-label={`${t("start_alt")} ${name}`}
              onClick={() => onAction(module)}
              size={buttonSize}
            >
              {t("start")}
            </Button>
          )}
        </div>
      );
    },
    [module, onAction, progress, status, thumbnailUrl]
  );

  const moduleCompleted = status === MODULE_STATUS.RESTART;
  const hasAwarenessAssessment = status === MODULE_STATUS.AWARENESS_ASSESSMENT;

  const StatusTag = useCallback(
    ({ tagSize }) => {
      const content = mandatory ? t("mandatory") : t("optional");
      return (
        <React.Fragment>
          {moduleCompleted && !hasAwarenessAssessment && (
            <Badge variant={"success"} content={t("complete")} size={tagSize}>
              {t("complete")}
            </Badge>
          )}

          {hasAwarenessAssessment && (
            <Badge
              variant={"optional"}
              content={t("moduleCard_tag_refresh")}
              size={tagSize}
            >
              {t("moduleCard_tag_refresh")}
            </Badge>
          )}
        </React.Fragment>
      );
    },
    [mandatory]
  );

  const props = {
    ActionButton,
    StatusTag,
    module,
    userLanguage,
    highLight,
  };

  return <ModuleVariant {...props} />;
};
