export const availableOn = (finishBy: string, limit: number): Date => {
  const finishDate = new Date(finishBy);
  const availableOnDate = new Date(
    Date.UTC(finishDate.getUTCFullYear(), finishDate.getUTCMonth(), finishDate.getUTCDate())
  );

  // Subtract the limit in days (converted to milliseconds)
  availableOnDate.setUTCDate(availableOnDate.getUTCDate() - limit);

  return availableOnDate;
};
