import React from "react";
import Loading from "shared/components/loading";
import { useTranslation } from "react-i18next";
import {
  Card,
  Title,
} from "@sosafe-platform-engineering/fe-lib-ui-mantine-react";
import useUser from "shared/hooks/use-user";
import style from "./welcome-card.module.css";
import { UseWelcomeCardMessage } from "./welcome-card-messages/use-welcome-card-message";
import { limitedContentDays } from "../shared/limited-learning-path-days";

export const WelcomeCard = () => {
  const { t } = useTranslation("flamingo");
  const { user } = useUser({ enabled: false });
  const welcomeText = user?.firstname
    ? `${t("welcome")} ${user?.firstname}!`
    : t("welcome-code-user");

  const userLanguage = user?.language || "en";
  const learningDaysLimit = limitedContentDays(
    user?.customer.id || 0,
    user?.campaign?.module_unlock_days || 0
  );
  const MessageComponent = UseWelcomeCardMessage(
    userLanguage,
    learningDaysLimit
  );

  if (!MessageComponent) {
    return <Loading />;
  }

  return (
    <Card withBorder radius={"lg"} className={style.welcomeCard}>
      <Title className={style.heading} order={2} size="h4">
        {welcomeText}
      </Title>
      <MessageComponent />
    </Card>
  );
};
